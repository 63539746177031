import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import HTML5Video from '../global/video.js';
import KaylaVideo from '../../video/kayla_thriving.mp4'
import BackToTop from "../global/BackToTop.js";
import ClosedCaptions from 'file-loader!../../closed-captions/kayla-quimbley.vtt';

class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      VideoSrc: undefined,
      VideoThumb: undefined
    };
  }
  handleClose = () => {
    this.setState({ show: false })
  }
  handleShow = () => {
    this.setState({ show: true })
  }
  handleClick = (video) => {
    let VideoSrc;
    let VideoThumb;
    let VideoTrackLabel;
    switch (video) {
      case "BlackWomenInHIV":
        VideoThumb = '../../celebrating-black-women-leaders-hero.jpg'
        VideoSrc = KaylaVideo
        VideoTrackLabel = "Celebrating Black Women in HIV"
        break;
    }
    this.setState({
      show: true,
      VideoThumb: VideoThumb,
      VideoSrc: VideoSrc,
      VideoTrackLabel: VideoTrackLabel
    })
    //this.handleShow();
  }
  render() {
    return (

      <div className={`${this.props.show ? "d-block" : "d-none"}`}>

        <Container fluid className={`hero ${this.props.page}`} />

        <Container className={`${this.props.page} px-0`}>
          <Row>
            <Col xs={10} className='m-auto px-0'>


              <div className={`hero-callout muted-yellow-dark`}>
                <h1 className='sub-title '>From poetry to presidents:</h1>
                <h1 className='title'>Shifting Perceptions of HIV<br className="d-none d-lg-block" /> Through Activism and Artistry </h1>
              </div>


              <img
                onClick={() => this.handleClick("BlackWomenInHIV")}
                src={"/kayla-quimbley-video-thumbnail.jpg"}
                alt="Kayla Quimbley Thumbnail"
                className="video thumbnail w-100 mt-4"
              />
              <p className="intro">
                Kayla Quimbley is a poet with a powerful perspective—she was born with HIV. Now, as the youngest member of
                the Presidential Advisory Council on HIV/AIDS, she has become a fierce advocate for people who acquired HIV through
                vertical transmission (passed from mother to child). Kayla spoke on her lived experience in a performance during
                the Gilead plenary session at USCHA 2023. Kayla is shifting perspectives, challenging the narratives that surround
                HIV, and showing people what it means to live and thrive with HIV through her artistry. Check out her performance in the video above and
                learn more about her personal story <a href="/championsofchange/kayla">here</a> to discover how her strength and courage helped her become a champion of HIV&nbsp;advocacy.
              </p>

              <HTML5Video
                handleClose={this.handleClose}
                show={this.state.show}
                url={this.state.VideoSrc}
                videoTrackLabel={this.state.VideoTrackLabel}
                closedCaptions={ClosedCaptions}
              />

              <h2 className='title mt-4 pt-3'>Related Content</h2>

              <Container className="related-content section mt-2 pt-1 mb-4">
                <Row>
                  <Col xs={12} className="m-auto">
                    <Container>
                      <Row className="justify-content-lg-between">

                        <a className="light-grey related-content-card mb-3 mb-lg-4" href="../../championsofchange/kayla">
                          <h3 className="related-content-card-header">
                            Kayla Quimbley: A Passionate Poet Challenging the Narrative
                          </h3>
                          <p className="related-content-card-subtext">
                            Born with HIV, Kayla is a poet who uses her powerful perspective to advocate for others and show people what it means to thrive with HIV. Learn
                            more about Kayla—one of our Champions of Change honorees—and how she's using her lived experience to shatter narratives around HIV.
                          </p>
                          <span className="read-more">
                            <span className="font-heavy"><span className="more-link">Learn more</span></span><span className="empty-space">&nbsp;&nbsp;&nbsp;</span><i className="fa fa-chevron-right "></i>
                          </span>
                        </a>

                        <a className="light-grey related-content-card mb-3 mb-lg-4" href="/championsofchange">
                          <h3 className="related-content-card-header">
                            Champions of Change: A Celebration of Black Women Changemakers in HIV
                          </h3>
                          <p className="related-content-card-subtext">
                            A tribute celebrating 10 inspirational Black women leaders in the HIV movement whose trailblazing work has contributed to changing the course of the HIV epidemic and paved the way for future&nbsp;leaders.
                          </p>
                          <span className="read-more">
                            <span className="font-heavy"><span className="more-link">Learn more</span></span><span className="empty-space">&nbsp;&nbsp;&nbsp;</span><i className="fa fa-chevron-right "></i>
                          </span>
                        </a>

                        <a className="light-grey related-content-card mb-3 mb-lg-4" href="../../community-involvement/blind-angels/dazon">
                          <h3 className="related-content-card-header">Empowering Black Women <br className="desktop" />in the American South to <br className="desktop" />Protect Their Sexual Health</h3>
                          <p className="related-content-card-subtext">Learn about the work Dázon Dixon Diallo is doing to improve access to care for women who live with and are at risk for HIV.</p>
                          <span className="read-more">
                            <span className="font-heavy"><span className="more-link">Read more</span></span><span className="empty-space">&nbsp;&nbsp;&nbsp;</span><i className="fa fa-chevron-right "></i>
                          </span>
                        </a>

                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>

              <Container className="px-0">
                <Row>
                  <Col xs={12} className="px-0 mb-4">
                    <BackToTop />
                  </Col>
                </Row>
              </Container>

            </Col>
          </Row>
        </Container>

      </div>


    );
  }
};

export default Content;
